<template>
    <button @click="$emit('onClickAdd')" type="button" class="btn btn-primary btn-add-new waves-effect waves-float waves-light">
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-plus me-25"><line x1="12" y1="5" x2="12" y2="19"></line><line x1="5" y1="12" x2="19" y2="12"></line></svg>
        <span class="align-middle">{{ title }}</span>
    </button>
</template>

<script>
export default {
    name: 'AddButton',
    props: {
        title: {
            default: 'Add Item'
        }
    }
}
</script>
