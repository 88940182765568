<template>
    <div style="border-radius: 8px" class="row journal-line">
        <div class="col-12 d-flex product-details-border position-relative pe-0">
            <div class="row w-100 pe-lg-0 me-1 py-2">
                <div class="col-md-6">
                    <div class="col-lg-8 col-12 mb-lg-0 mb-2 mt-lg-0 mt-2">
                        <label class="form-label"><span class="required">*</span> Business name</label>
                        <input type="text" placeholder="business name" class="form-control" v-model="data.name">
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="col-lg-8 col-12 mb-lg-0 mb-2 mt-lg-0 mt-2">
                        <label class="form-label">Type</label>
                        <v-select
                            placeholder="Select Business Type"
                            v-model="data.type"
                            :options="unitTypes"
                            label="name"
                            :reduce="types => types.value"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import handleCBusinesses from '@/services/modules/businesses';
const { fetchBusinessUnitTypes } = handleCBusinesses()
export default {
    name: 'AddFormElement',
    props: {
        data: {
            type: Object
        }
    },
    data(){
        return {
            unitTypes: []
        }
    },
    methods: {
        getQuery(){
            return `?company_id=${this.$route.params.companyId}`;
        }
    },
    async mounted(){
        await fetchBusinessUnitTypes(this.getQuery()).then((res)=> {
            if(res.status) this.unitTypes = res.data;
        })
    },
}
</script>
<style scoped>
.journal-line {
    border: 1px solid #f0f2f5;
}

.journal-line:hover {
    background: #f0f2f5;
}
</style>
